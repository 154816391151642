@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
html, body{
    min-width: auto !important;
}
body{
	font-family: 'Lato', sans-serif;
    overflow-y: auto !important;
}
*{
    box-sizing: border-box;
}
.container{
	max-width: 1140px;
	margin: 0 auto;
    width: 100%;
}
header{
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
}
.header{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-image: url('./header-bg.png');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
    align-items: center;
}
.header-middle a {
    padding: 10px 15px;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: #454545;
    font-weight: bold;
}
.header-middle a:hover {
    text-decoration: none;
    color: #454545;
}
.header-middle .menu-widget {
    display: flex;
    margin: 0;
}
.main-content{
    box-sizing: border-box;
}
.main-content .top-gap{
    padding-top: 93px !important;
}
.main-content.main-content-no-pdding{
    padding-top: 0px;
}
.main-content.padding-zero{
    padding: 0px;
}
.programs-menu.dropdown-menu,
.profile-dropdown .MuiMenu-paper .MuiMenu-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    border-top: none;
}
.programs-menu.dropdown-menu li,
.profile-dropdown .MuiMenu-paper .MuiMenu-list li {
    padding: 10px 25px;
    font-weight: bold;
    color: #454545;
}
.programs-menu.dropdown-menu li a,
.profile-dropdown .MuiMenu-paper .MuiMenu-list li a {
    color: #454545;
    text-decoration: none;
}
.dropdown-menu li a{
    color: #454545;
    text-decoration: none;
}
/* .dropdown-menu .MuiMenu-paper, */
.profile-dropdown .MuiMenu-paper{
    margin-top: 50px;
}
.btn-number{
    border-radius: 20px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    margin-left: 10px !important;
    cursor: default !important;
}
.btn-login{
    border-radius: 20px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    margin-left: 10px !important;
    color: #3f51b5 !important;
    border-color: #3f51b5 !important;
}
.btn-curve {
    border-radius: 20px !important;
}
.btn-info {
    background-color: #3895FF !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
}
.btn-success {
    background-color: #0CBB80 !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
}
.btn-success-overlay {
    background-color: #CDF1E5 !important;
    color: #0CBB80 !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
}
.btn-white {
    background-color: #fff !important;
    color: #0CBB80 !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
}
.btn-white-outline {
    color: #FFFFFF !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
    border-color: #FFFFFF !important;
}
.mr-10 {margin-right: 10px !important;}
.btn-primary {
    background-color: #3895FF !important;
    text-transform: initial !important;
    padding: 10px 25px !important;
    color: #fff;
}
.btn-primary2 {
    background-color: #3895FF !important;
    text-transform: initial !important;
    color: #fff;
}
.btn-blue {
    background-color: #2B3195 !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
}
.btn-red {
    background-color: #EC262C !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
}
.btn-pay-small {
    padding: 0px 25px !important;
}
.btn-navlink {
    background-color: #3895FF !important;
    text-transform: none !important;
    text-transform: initial !important;
    border: 1px solid #3895FF !important;
    font-size: 0.875rem;
}
.btn-navlink a{
    padding: 8px 25px !important;
    display: block;
}
.w-100,
.btn-enroll {
    width: 100% !important;
}

.form-group{
    margin-bottom: 15px;
}

.course-slider-widget .slick-next,
.course-slider-widget .slick-prev {
    width: 32px;
    height: 32px;
}
.course-slider-widget .slick-prev::before {
    color: #9A9DA4 !important;
    font-size: 32px !important;
}
.course-slider-widget .slick-next::before {
    color: #9A9DA4 !important;
    font-size: 32px !important;
}
/* .course-slider-widget .slick-prev::before {
    content: " ";
    color: #333 !important;
    font-size: 32px !important;
    background-image: url('./arrow.png');
}
.course-slider-widget .slick-next::before {
    content: " ";
    color: #333 !important;
    font-size: 32px !important;
    background-image: url('./arrow.png');
} */
.course-slider-widget .slick-slide .course-slider .course-content {
    box-shadow: 0px 0px 6px #cacaca;
    padding: 60px 20px;
    margin: 20px;
    border-radius: 5px;
    text-align: center;
    background-color: #ffffff;
}
.course-slider-widget .slick-slide .course-slider .course-content h3{
    margin-top: 0px;
}
.course-slider-widget .slick-slide .course-slider .course-content h3{
    margin-top: 0px;
}
/* Login styles */

.login-widget {
    background-image: url('./login-bg.png');
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 10px;
}
.login{
    display: flex;
    width:850px;
    margin: 0 auto;
    min-height: calc(100vh - 150px);
}
.login-left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    flex: 0 0 29%;
    position: relative;
    z-index: 1;
}
.login-left::before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    background-color: #4271C9;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.login-left img{
    position: absolute;
    right: -35%;
    bottom: -10px;
    width: 385px;
}
.regist img{   
    position: absolute;
    top: 465px;
    left: 270px;
    width: 385px;
}

.setPswrdImg img{
    position: absolute;
    width: 385px;
    left: 273px;
    top:454px;
}

    .fgtPswrdImg img {
        position: absolute;
    width: 385px;
    left: 273px;
    top:368px;
}

@media (max-width:1429px) {
    .fgtPswrdImg img {
        position: absolute;
    width: 335px;
    left: 229px;
    top: 371px;
    }
}

@media (max-width:1280px) {
    .fgtPswrdImg img {
        position: absolute;
        width: 385px;
        left: 56px;
        top: 474px;
    }
}

@media (max-width:800px) {
    .fgtPswrdImg img {
        position: absolute;
    width: 250px !important;
    top: 611px;
    left: -1px;
}
}
@media (max-width: 540px){
.fgtPswrdImg img {
    position: absolute;
    width: 188px !important;
    top: 506px;
    left: -30px;
}
}

@media (max-width: 412px){
.fgtPswrdImg img {
    width: 145px !important;
    top: 502px;
    left: -5px;
    position: absolute;
}
}

.fgtPswrdOTPImg img {
    position: absolute;
width: 385px;
left: 283px;
top:388px;
}

@media (max-width:1429px) {
.fgtPswrdOTPImg img {
    position: absolute;
width: 335px;
left: 239px;
top: 382px;
}
}

@media (max-width:1280px) {
.fgtPswrdOTPImgimg {
    position: absolute;
    width: 385px;
    left: 66px;
    top: 474px;
}
}

@media (max-width:800px) {
.fgtPswrdOTPImg img {
    position: absolute;
width: 250px !important;
top: 621px;
left: -1px;
}
}
@media (max-width: 540px){
.fgtPswrdOTPImg img {
position: absolute;
width: 188px !important;
top: 516px;
left: -30px;
}
}

@media (max-width: 412px){
.fgtPswrdOTPImg img {
width: 145px !important;
top: 538px;
left: -5px;
position: absolute;
}
}

.newPswrdImg img {
    position: absolute;
width: 385px;
left: 303px;
top:440px;
}

@media (max-width:1429px) {
.newPswrdImg img {
    position: absolute;
width: 376px;
left: 87px;
top: 467px;
}
}

@media (max-width:1280px) {
.newPswrdImgimg {
    position: absolute;
    width: 385px;
    left: 76px;
    top: 474px;
}
}

@media (max-width:800px) {
.newPswrdImg img {
    position: absolute;
width: 250px !important;
top: 625px;
left: -41px;
}
}
@media (max-width: 540px){
.newPswrdImg img {
position: absolute;
width: 188px !important;
top: 592px;
left: -30px;
}
}

@media (max-width: 412px){
.newPswrdImg img {
width: 145px !important;
top: 562px;
left: -5px;
position: absolute;
}
}

@media (max-width:1280px) {
    .setPswrdImg img {
        position: absolute;
        width: 385px;
        left: 56px;
        top: 516px;
    }
}

@media (max-width:960px) {
    .setPswrdImg img {
        position: absolute;
        width: 250px !important;
        top: 684px;
        left: -8px;
}
}
@media (max-width: 540px){
.setPswrdImg img {
    position: absolute;
    width: 219px !important;
    top: 624px;
    left: -31px;

}}

@media (max-width: 412px){
.setPswrdImg img {
    width: 145px !important;
    top: 604px;
    left: -5px;
    position: absolute;
}
}

@media(max-width:1280px){
    .registWrapper{
        padding: 5% !important;
    }
}

@media(max-width:1429px){
    .regist img{
            position: absolute;
            top: 433px;
            left: 214px;
            width: 396px;
    }
}

@media(max-width:1280px){
    .regist img{    position: absolute;
        top: 470px;
        left: 72px;
        width: 358px;
    
    }
}

@media (max-width: 959px){
    .regist img{
        top: 744px;
        left: -7px;
        width: 200px;
}
    .registLeft{
       
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0px !important;
    }
    .registRight{
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;  
        border-top-right-radius: 0px !important; 
    }
}

@media (max-width: 750px){
    .regist img {
        /* visibility: hidden; */
        width: 124px !important;
        top: 766px;
        left: -9px;
    }
}  
.loginForm{
    display: flex;
}
@media (max-width: 1280px){
    .loginForm {
        display: flex;
        flex-direction: column !important;
    }
}  

.registLeft{
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}
.registRight{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
.login-left h3{
   color: #ffffff;
   text-align: center;
   margin-top: 50px;
   line-height: 35px;
}
.login-right{
    flex: 0 0 71%;
    position: relative;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.login-info{
    padding-top: 50px;
}
.login-right >div{
   max-width: 400px;
   margin: 0 auto;
   width: 100%;
}
.login-logo {
    text-align: center;
    margin-top: 25px !important;
}
.login-logo img {
    width: 200px;
}
.cap{
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 65px;
    height: 65px;
    transform: translate(30px, 30px);
}
.cap.cap-signup{
    bottom: 25px;
}
.cap img{
    width: 100%;
}

.sucessFooter{
    padding-left: 4%;
    padding-right: 4%;
}
.footer-widget{
    background-image: url('./oval_footer.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 120px 0 0;
    position: inherit;
}
.footer-widget::before{
    content: "";
    position: absolute;
    background-image: url('./diamond.png');
    background-repeat: no-repeat;
    background-position: 95% 75px;
    background-size: contain;
    width: 300px;
    height: 300px;
    right: 45px;
    z-index: -1;
}
.footer-widget footer {
	display: flex;
	max-width: 1200px;
	width: 100%;
	margin: 40px auto 0;
	color: #747475;
	align-items: flex-end;
	padding-bottom: 20px;

}
footer h3{
	margin-top: 0;
}
footer .footer-left,
footer .footer-middle{
	flex: 0 0 30%;
}
footer .footer-right{
	flex: 1;
    padding-top: 160px;
}
footer ul{
	list-style: none;
	padding: 0;
	margin: 0 0 20px 0;
}
.footer-left-ul{
	margin-top: 80px;
	margin-bottom: 0;
}
.footer-left-ul li{
	margin-top: 10px;
}
.footer-left-ul a{
	font-size: 18px;
	font-weight: bold;
    cursor: pointer;
}
.footer-left-ul p{
	width: 70%;
	line-height: 30px;
}
.footer-block form{
	background-color: #ffffff;
}
.footer-block ul li {
	line-height: 30px;
}
.footer-block ul li a{
	cursor: pointer;
}
.main-content >div{
    padding: 0 !important;
}
.btn-login2 {
    background-color: #3895FF !important;
    color: #fff !important;
    display: flex !important;
    justify-content: space-between !important;
    margin: 0 !important;
    border-radius: 20px !important;
    padding: 5px 25px !important;
    text-transform: initial !important;
    font-family: 'lato' !important;
    font-size: 16px !important;
}
.btn-icon{
    display: flex;
    align-items: center;
}
.btn-icon svg{
    font-size: 28px;
}
.btn-signup{
    margin-bottom: 20px !important;
    color: #3895FF !important;
    background-color: #E3EDF8 !important;
    text-transform: initial !important;
    padding: 5px 25px !important;
    font-family: 'Lato' !important;
    font-size: 16px !important;
}
/* .r-borderColor-1jyn79y {
    background-color: #3895FF !important;
    border-color: #3895FF !important;
}
.r-backgroundColor-11udlyb {
    background-color: #3895FF !important;
} */
.btn-icon-left{
    padding-right: 5px;
    display: flex;
}
.btn-icon-left svg{
   font-size: 20px;
}
/* Profile styles start*/
.profile-view{
    display: flex;
    padding-top: 10px;
}
.profile-image {
    position: relative;
}
.edit-profile-pic{
    position: absolute;
    right: 5px;
    bottom: 5px;
}
.btn-edit-profile{
    border-radius: 50px;
    border: 1px solid #000;
    padding: 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.profile-image span{
    display: block;
    width: 255px;
    height: 255px;
    border: 1px solid #ccc;
    border-radius: 7px;
    position: relative;
    padding: 10px;
}
.profile-image span img{
   width: 100%;
   height: 100%;
   border-radius: 10px;
   object-fit: cover;
}
.profile-name{
    color: #292D97;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.profile-name h1{
    margin: 0;
}
.profile-details{
    padding-left: 50px;
    flex: 1;
    display: flex;
    width: 100%;
}
.profile-sub{
    display: flex;
    flex-wrap: wrap;
}
.profile-sub .form-group{
    flex: 0 0 50%;
    margin-bottom: 25px;
}
.field-label{
    color: #a9a8a8;
    font-size: 14px;
}
.field-value{
    color: #454545;
    font-weight: bold;
    font-size: 18px;
    margin-top: 5px;
}
.btn-edit{
    padding: 5px 25px !important;
    background-color: #3895FF !important;
}
.profile-sub-edit{
    display: flex;
    flex-wrap: wrap;
}
.profile-sub-edit .form-group{
    margin-bottom: 25px;
    flex: auto;
}
.side-by-side .form-group:last-child{
    padding-left: 30px;
}
.profile-details-left{
    width: 100%;
}
.profile-details-right{
   padding-left: 20px;
}
.form-group .MuiFormControl-root{
    width:100% !important;
}
.form-group .MuiSelect-select{
    padding: 12px !important;
}
/* Profile styles end*/
/* scorecard list styles start */
.scorecard-list ul{
    margin: 40px 0 0;
    padding: 0;
    list-style: none;
}
.scorecard-list ul li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 25px;
    flex-wrap: wrap;
}
.scorecard-img{
    width: 100px;
    height: 100px;
    display: flex;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
}
.scorecard-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0px 0px 6px #cacaca;
}
.scorecard-img svg{
    font-size: 100px;
    color: #67cfac;
}
.scorecard-info{
    flex-grow: 1;
}
.scorecard-info h3{
    margin: 0;
    color: #454545;
    font-size: 21px;
}
.course-type{
    padding: 5px 0;
    color: #3895FF;
    font-size: 18px;
    display: block;
}
.scorecard-list .date{
    color: #B2B2B2;
    font-style: italic;
    font-family: 'Lato';
}
.scorecard-list .rank,
.scorecard-list .marks,
.scorecard-list .result-status{
    font-size: 21px;
    font-weight: 600;
    color: #454545;
    padding: 0 15px;
}
.scorecard-list .congrats{
    font-size: 18px;
    font-weight: 600;
    color: #12B880;
}
.result{
    min-width: 150px;
}
/* scorecard list styles end */
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: "Lato";

}
.form-select {
    display: block;
    width: 100%;
    padding: 6px 25px 6px 10px;
    -moz-padding-start: calc(0.75rem - 3px);
    font: 1rem system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-select:focus-visible,
.form-select:focus {
    color: #212529 !important;
    background-color: #fff;
    border-color: #212529 !important;
    box-shadow: 0 0 0 1px #212529 !important;
}
.form-control:focus-visible,
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #212529 !important;
    box-shadow: 0 0 0 1px #212529 !important;
}
/* Home styles start */

.top-section{
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
	align-items: center;
	background-image: url('./background.png');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;

}
.top-section-left{
	flex: 0 0 40%;
}
.top-section-left h1{
    color: #4645A2;
}
.top-section-left p{
    color: #707374;
	line-height: 30px;
}
.top-section-left button{
	margin-top: 20px;
    border-radius: 25px;
    padding: 10px 25px;
	background-color: #2E81F7 !important;
}
.top-section-left button:hover{
	background-color: #1968d6 !important;
}
.top-section-right{
	flex: 0 0 60%;
}
.top-section-right img{
	width: 100%;
}
.question_papers,
.courses {
	background-image: url('./circles-group.png');
	background-position: 20px 100px;
	background-repeat: no-repeat;
}
.courses h3{
	text-align: center;
	margin-top: 40px;
}
.courses p{
	text-align: center;
    padding: 0px 40px 40px;
    line-height: 30px;
    margin: 0;
}
.courses-list {
	margin: 30px 0 0;
	padding: 0;
	list-style: none;
    display: flex;
    flex-wrap: wrap;
	grid-template-columns: auto auto auto;
	gap: 80px 50px;
}
.courses-list li{
	box-shadow: 0px 0px 6px #b1b1b1;
	border-radius: 15px;
	text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 34px);
	background-color: #fff;
}
.courses-list li .course-info{
	text-decoration: none;
    display: block;
}
.courses-list li .course-color-circle{
	width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #999;
    background-color: #333;
    display: inline-block;
    margin-top: -20px;
}
.courses-list li.li-imgage{
	flex: 1 1 30%;
	box-shadow: none;
    flex-grow: 0;
}
.li-imgage .courses-image{
	display: flex;
    align-items: center;
    justify-content: center;
}
.courses-image img{
	width: 100%;
}
.section-heading {
    margin-bottom: 50px;
}
.section-heading h3{
	text-align: center;
    margin-top: 40px;
    margin-bottom: 10px;
	color: #444444;
}
.section-heading h3 span{
	background-image: url('./circle-bg.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: contain;
    padding: 10px 10px 10px 20px;
    display: inline-block;
}
.section-heading p{
    text-align: center;
    padding: 0px 40px 40px;
    line-height: 30px;
    margin: 0;
	color: #454545;
}
.section-heading-left h3 span{
	background-image: url('./circle-bg.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: contain;
    padding: 10px 10px 10px 20px;
    display: inline-block;
}
.w-800-center{
	max-width: 800px;
    margin: 0 auto;
}
.w-900{
	max-width: 900px;
}
.w-1000{
	max-width: 1000px;
}
.our-testimonials{
	display: flex;
}
.our-testimonials-left {
	padding-right: 50px;
    flex: 1;
}
.our-testimonials-left p{
	line-height: 30px;
}

.testimonals-info .name,
.our-testimonials-left .name{
	color: #0EBC7F;
	font-size: 18px;
	font-weight: bold;
}
.testimonals-info .name h2,
.testimonals-info .name h4,
.our-testimonials-left .name h2,
.our-testimonials-left .name h4{
	margin: 0px;
}
.our-testimonials-right {
	flex:0 0 40%;
}
.our-testimonials-right img{
	width: 100%;
}

.upcoming-batches{
    display: grid;
	grid-template-columns: auto auto auto;
    gap: 90px;
	margin-top: 30px;
}
.batch-info{
    flex: 0 0 33.33%;
    display: flex;
    flex-direction: column;
}
.upcoming-batches .batch-info:nth-child(2) .batch-info-image,
.upcoming-batches .batch-info:nth-child(5) .batch-info-image,
.upcoming-batches .batch-info:nth-child(8) .batch-info-image{
   order: 2;
}
.batch-info-image{
	margin-top: 20px;
}
.batch-info img{
    width: 300px;
    height: 300px;
    border-radius: 15px;
    box-shadow: 0 0 10px #6d6d6d;
    object-fit: cover;
    object-position: top center;
}
.batch-info a {
    color: #40B58D;
    text-decoration: underline;
}
.batch-info h2 {
	color: #454545;
}
.batch-info p {
	line-height: 30px;
    color: #454545;
}
.why-nursing-experts {
    padding-top: 100px;
}
.why-nursing-experts ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0 20px;
    padding: 0;
    justify-content: center;
}
.why-nursing-experts ul li{
	font-size: 16px;
    padding-right: 75px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    color: #4A4A4A;
}
.why-nursing-experts ul li span.expert-name{
	padding-left: 15px;
}
.why-nursing-experts ul li span.expert-image img{
	width: 50px;
}
.success-story{
	display: flex;
    align-items: center;
    justify-content: center;
}
.modal-data{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.success-story-video{
	display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;
}
.success-story-iframe{
	display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
}
.success-story-left{
	flex: 0 0 40%;
}
.success-story-left h3 span{
	background-image: url('./circle-bg.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: contain;
    padding: 10px 10px 10px 20px;
    display: inline-block;
}
.success-story-left p{
	line-height: 30px;
}
.success-story-right{
	display: flex;
	align-items: center;
	flex: 0 0 60%;
    cursor: pointer;
    padding: 50px;
    position: relative;
}
.success-story-right img{
    width: 600px;
    height: 400px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: -30px 30px 30px #0000004f;
    background-color: #fff;
    border: 1px solid #000;
}
.live{
	margin-left: 20px;
    background-color: #0CBB80;
    color: #fff;
    border-radius: 20px;
    padding: 2px 20px;
    font-size: 0.7rem;
    font-family: 'Lato';
}
.btn-profile {
    background-color: transparent !important;
    color: #2A2C98 !important;
    border-radius: 20px !important;
    padding: 5px 10px !important;
    text-transform: initial !important;
    box-shadow: none !important;
}
.btn-profile.active {
    background-color: #0CBB80 !important;
    color: #ffffff !important;
    border-radius: 20px !important;
    padding: 5px 10px !important;
    text-transform: initial !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.btn-profile img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
.btn-profile span{
   font-weight: bold;
   padding-left: 10px;
   display: flex;
}
.btn-profile .user-name{
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    white-space: nowrap;
}
.new-courses {
	background-image: url('./oval-left-bottom.png');
	background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.social-links{
	display: flex;
	margin: 0;
}
.social-links li {
	margin-right: 10px;
}
.social-links li a{
	display: flex;
    align-items: center;
    color: #ffffff;
    border-radius: 7px;
    padding: 5px;
}
.social-links li a.facebook{
	background-color: #5F81C8;
}
.social-links li a.twitter{
	background-color: #68D5F6;
}
.social-links li a.linkedin{
	background-color: #258DD6;
}
.social-links li a.instagram{
	background-color: #E86A6A;
}
.store-links  {
    margin-top: 20px;
}
.store-links  a{
    display: inline-block;
}
.store-links a img {
    width: 150px;
    padding-right: 20px;
}
.footer-block .form-group > div{
	width: 100%;
}
.btn-toggle{
    cursor: pointer;
    padding: 3px;
    border: 1px solid #fff;
    border-radius: 3px;
    min-width: 25px;
    height: 32px;
    line-height: 32px;
}
.error-field{
    border-color: #B86F72;
    box-shadow: 0 0 0 1px #B86F72 !important;
}

.form-control.error-field:focus-visible,
.form-control.error-field:focus,
.error-field:focus-visible,
.error-field:focus{
    border-color: #B86F72 !important;
    box-shadow: 0 0 0 1px #B86F72 !important;
}
.file-upload-area.error-field {
    border-color: #B86F72 !important;
    box-shadow: none !important;
}
.error{
    color: #B86F72;
    font-size: 12px;
}
.error-message{
    color: #B86F72;
    font-size: 12px;
    padding: 5px 0;
}
.custom-radio{
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    height: 40px;
    align-items: center;
    color: #75758b;
}
.custom-radio > div{
    display: block;
}
/* Home styles end */
/* Footer styles start */
.main-content .register{
	position: relative;
    padding: 100px 0 !important;
}
.register img{
	width: 100%;
}
.register .register-info {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	justify-content: space-around;
	color: #fff;
    box-sizing: border-box;
    padding: 0 100px;
}
.register-info h3{
	margin-top: 0;
}
.btn-register{
	background-color: #ffffff !important;
	color: #3895FD !important;
	font-weight: bold !important;
	font-weight: 600 !important;
    font-size: 16px !important;
}
.btn-register a{
	color: #3895FD !important;
    text-decoration: none;
}
.btn-register-now{
	background-color: #3895FF !important;
	font-weight: bold !important;
	font-weight: 600 !important;
    font-size: 16px !important;
}
.btn-register-now a{
	color: #fff !important;
    text-decoration: none;
}
.btn-register-now:hover{
	background-color: #1c6dc8 !important;
}
.footer-logo{
	width: 200px;
}
/* Footer styles end */
/* Vacancies Styles start */
.vacancies ul{
    margin: 15px -15px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.vacancies ul li{
    flex: 0 0 33.33%;
}
.vacancies ul li>a{
    text-decoration: none;
}
.vacancy-info {
    margin: 25px;
    padding: 40px;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    display: block;
}
.vacancy-info img{
    width: 250px;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
.vacancy-info h3{
   color: #454545;
   margin-bottom: 5px;
}
.vacancy-info span{
   color: #9a9a9a;
   font-style: italic;
   font-size: 14px;
}
/* Vacancies Styles end */
/* Classromm Courses styles start */
.classroom-courses {
    margin: 0 -15px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.classroom-courses li{
    flex: 0 0 33.33%;
    margin: 25px 0;
}
.classroom-info{
    margin: 0 25px;
    padding: 40px;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px;
    text-align: center;
    /* cursor: pointer; */
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.classroom-info img{
    width: 250px;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
.classroom-course-image{
    display: block;
    height:200px;
    max-height:200px;
}
.classroom-info h3{
    color: #454545;
    margin-bottom: 5px;
 }
 .classroom-info p{
    color: #9a9a9a;
    font-size: 14px;
 }
 .classroom-info a{
    text-decoration: none;
 }
/* Classromm Courses styles end */
.recorded-videos ul{
    margin: 0 -15px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.recorded-videos ul li{
    flex: 0 0 50%;
}
.recorded-videos ul li .record-content{
    margin: 25px;
    padding: 40px;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: "Lato";
    text-decoration: none;
}
.record-img{
    width: 100px;
    display: flex;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
}
.record-img svg{
    font-size: 100px;
    color: #67cfac;
}
.record-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0px 0px 6px #cacaca;
}
.record-info{
    text-align: left;
}
.record-info h3{
    margin: 0;
    color: #454545;
    font-size: 21px;
}
.record-time{
    padding: 5px 0;
    color: #3895FF;
    font-size: 18px;
    display: flex;
    align-items: center;
}
.record-time svg{
    font-size: 26px !important;
    padding-right: 5px;
}
.record-date{
    color: #8B8B8B;
    font-style: italic;
}
.login-left-mobile{
    display: none;
    position: absolute;
    bottom: 0;
    left: -20px;
    width: 125px;
}

/* Our Programs styles start */
.our-programs ul{
    list-style: none;
    margin: 0 -15px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.our-programs ul li{
    flex: 0 0 33.33%;
}
.program-info{
    text-align: center;
}
.program-content{
    margin: 25px;
    box-shadow: 0px 0px 6px #cacaca;
    position: relative;
    display: flex;
    border-radius: 10px;
}
.program-content img{
    border-radius: 10px;
    width: 100%;
    height: 280px;
    object-fit: cover;
}
.program-content span{
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    opacity: 0.7;
}
.program-content span.one{
    background-color: #C25761;
}
.program-content span.two{
    background-color: #2B76D6;
}
.program-content span.three{
    background-color: #15A67D;
}
.program-info h3{
    color: #454545;
    padding: 0 85px;
    line-height: 30px;
}
.our-programs p {
    font-size: 18px;
    color: #454545;
    line-height: 30px;
    text-align: center;
    margin-bottom: 40px;
}
/* Our Programs styles end */
/* Crash course styles start */
.enroll-now-widget {
    position: relative;
    background-color: #fff;
}
.enroll-now-widget img{
    width: 100%;
    box-shadow: 0px 0px 12px #8d8484;
    margin-bottom: 15px;
    border-radius: 15px;
    margin-top: 10px;
}
.enroll-now-widget::before{
    content: "";
    position: absolute;
    background-image: url('./circles-group.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100%;
    width: 200px;
    height: 260px;
    left: -30px;
    z-index: -1;
    top: -18px;
}
.about-sec1-right .enroll-now-widget::before{
    right: -30px;
    left: inherit;
}
.about-sec1-right .enroll-now-widget img{
    min-height: 300px;
    object-fit: cover;
}
.course-list {
    padding-left: 25px;
}
.course-list h3{
    color: #454545;
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 30px;
}
.course-list ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.course-list ul li{
    padding-bottom: 20px;
}
/* .course-list ul li:first-child{
    display: none;
} */
.list-info{
    display: flex;
    align-items: flex-start;

}
.list-image{
    padding: 0 10px 0 0;
}
.list-desc{
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-word;
}
.list-desc ul li{
    position: relative;
}
.list-desc ul li::before{
    content: "";
    position: absolute;
    background-image: url('./Combined-Shape.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    left: -30px;
    z-index: -1;
    top: 0;
}
.list-image img{width: 21px;}
.widget-details {
    padding: 30px;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px 100px 10px 100px;
    color: #454545;
    position: relative;
    background-color: #fff;
    width: 100%;
}
.widget-details::before{
    content: "";
    position: absolute;
    background-image: url('./circles-group.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100%;
    width: 200px;
    height: 260px;
    left: -30px;
    z-index: -1;
    top: 0;
}
.group-widget-double{
    display: flex;
}
.group-widget-double .group-widget {
    flex: 1;
}
.group-widget-double .group-widget:nth-child(2) {
    padding-left: 15px;
    min-width: 100px;
}
.group-widget {
    margin-bottom: 20px;
}
.group-widget-auto {
    flex: auto !important;
}
.group-widget h4{
    margin: 0 0 3px 0;
    font-size: 17px;
}
.group-value ol {
    padding-left: 15px;
    margin: 0;
}
.group-value ol li{
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 8px;
}
.mt-40 {margin-top: 40px !important;}
.demo-slider{
    display: flex;
    max-width: 500px;
}
.demo-slider-widget .slick-slide .demo-slider .demo-content {
    /* box-shadow: 0px 0px 6px #cacaca; */
    padding: 0px;
    margin: 20px;
    border-radius: 5px;
    text-align: center;
    /* background-color: #ffffff; */
    position: relative;
    flex: 0;
}
.demo-slider-widget .slick-slide .demo-slider .demo-content iframe{
    border-radius: 10px;
    border: none;
    background-color: #000;
}
.demo-slider-widget .slick-slide .demo-slider .demo-content h3{
    margin-top: 0px;
}
.demo-slider-widget .slick-slide .demo-slider .demo-content h3{
    margin-top: 0px;
}
.demo-content > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.play{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgb(161 161 161 / 70%); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.play h3{
    position: absolute;
    bottom: 10px;
    left: 30px;
    color: #fff;
    font-size: 21px;
}
.demo-slider-widget .slick-next,
.demo-slider-widget .slick-prev {
    width: 32px;
    height: 32px;
}
.demo-slider-widget .slick-prev::before {
    color: #9A9DA4 !important;
    font-size: 32px !important;
}
.demo-slider-widget .slick-next::before {
    color: #9A9DA4 !important;
    font-size: 32px !important;
}
/* Crash course styles end */
/* Global Study course styles start */
.address {
    padding: 30px;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px 100px 10px 100px;
    color: #454545;
    position: relative;
    background-color: #fff;
    width: 100%;
    margin-top: 60px;
}
.address::before{
    content: "";
    background-image: url('./yellow.png');
    width: 100%;
    height: 100%;
    position: absolute;
    right: -100px;
    top: -65px;
    background-repeat: no-repeat;
    background-position: top right;
    z-index: -1;
}
.address.quote-green-circle::before{
    content: "";
    background-image: none;
    background-color: #0CBB80;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: -30px;
    background-repeat: no-repeat;
    background-position: top right;
    z-index: -1;
    box-shadow: 0px 0px 12px #333;
}
.address p {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    display: flex;
}
.square-course{
    list-style: none;
    padding: 0;
    margin: 40px 0 0;
    display: flex;
}
.square-course li{
    margin-right: 40px;
}
.square-content{
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0px 0px 6px #cacaca;
    position: relative;
    overflow: hidden;
    min-width: 125px;
    min-height: 125px;
    align-items: flex-end;
    display: flex;
}
.square-course li a{
    text-decoration: none;
    color: #454545;
}
.square-content h3{
    margin: 0;
}
.square-color-circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -15px;
    left: -15px;
}
.documents-required h3{
    margin-bottom: 5px;
}
.documents-required ol{
    padding-left: 15px;
    margin: 0;
}
.documents-required li{
    padding-bottom: 5px;
    color: #454545;
    
}
.text-right{
    text-align: right;
}
.quote {
    display: inline-block;
}
.quote img{
    width: 100%;
}
.start-quote {
    padding-right: 15px;
    margin-top: -15px;
    float: left;
}
.end-quote {
    padding-left: 15px;
    margin-bottom: -15px;
    float: right;
    display: flex;
    align-items: end;
}
.global-study-image {
   padding-left: 50px;
   text-align: center;
}
.global-study-image img{
    width: 100%;
}
.registration{
    margin-top: 50px;
}
.documents-required h3,
.registration h3{
   color: #454545;
   font-size: 18px;
}
.dropzone {
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .selected-file-wrapper {
    text-align: center;
  }
  
  .selected-file {
    color: #000; 
    font-weight: bold;
  }
  .multi-preview ul{
      list-style: none;
      margin: 0 -10px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
  }
  .multi-preview li {
      position: relative;
  }
  .multi-preview li .upload-div{
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0px 0px 6px #454545;
      margin: 10px;
      position: relative;
      min-width: 125px;
      min-height: 125px;
      text-align: center;
      justify-content: center;
      display: flex;
  }
  .multi-preview li .delete {
      display: block;
      position: absolute;
      top: 13px;
      right: 13px;  
      z-index: 1;
      cursor: pointer;
  }
  /* .multi-preview li:hover a{
      display: block;
  } */
  .multi-preview li a svg{
      color: #E86A6A;
  }
  .multi-preview img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
  }
  .file-upload-area{
    width: 100%;
    height: 70px;
    border: 2px dashed #C7D3E1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    background-color: #fff;
  }
  .file-upload-area input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
  }
  .pdf-file{
      display: flex;
      align-items: center;
      justify-content: center;
      color: #f00;
  }
  .pdf-file svg{
      font-size: 70px;
  }
  .doc-name{
    padding: 0 10px;
    text-align: center;
    max-width: 145px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
/* Global Study course styles end */
/* question papers styles start */
.question-papers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    list-style: none;
    padding: 0px;
}
.question-papers li{
    flex: 0 0 33.33%;
    padding-top: 30px;
}
.question-papers-info{
    margin: 15px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #cacaca;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}
.btn-year{
    padding: 15px 40px !important;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px !important;
    box-shadow: 0px 0px 10px #a3a3a3;
    text-decoration: none;
}
.question-papers-btn{
    margin-top: -25px;
}
.classroom-course-type{
    padding: 10px 15px;
    line-height: 21px;
    font-size: 16px;
}
.question-papers-name{
    color: #454545;
    margin-top: 40px;
}
.sub-menu-widget {
    display: none;
}
.sub-menu-widget ul{
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 0px;
    transition: all 0.3s;
    overflow: hidden;
}
.sub-menu-widget ul.open{
    max-height: 500px;
    transition: all 0.3s;
}

/* question papers styles end */
.play-story{
    position: absolute;
    bottom: 30px;
    right: 30px;
}
.testimonial-info-right{
    padding-left: 30px;
}
.testimonals-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.testimonals-list li{
    display: flex;
    margin-bottom: 100px;
}
.testimonals-list li:nth-of-type(even) .image{
    order: -1;
}
.testimonals-list li:nth-of-type(even) .testimonals-info {
    padding-left: 100px;
    padding-right: 0px;
}
.testimonals-list li .image {
   width: 45%;
   flex: 0;
   height: fit-content;
}
.testimonals-list li .image img{
   width: 100%;
}
.testimonals-info{
    width: 55%;
    flex: 1;
    padding: 0 100px 0 0;
}
.welcome-widget{
    background-image:url('./-g-Group.png');
    background-repeat: no-repeat;
    background-position: right;
    height:100%;
    min-height: 100vh;
}
.welcome-info {
    min-height: 100vh;
    align-items: baseline;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.welcome-info img{
    left: 50px;
    top: 50px;
    position: absolute;
}
.welcome-content{
    padding-left: 50px;
    max-width: 520px;
    width:100%
}
.image  {
   position: relative;
}
.global-study-image .image  {
    width: 300px;
    margin: 0 auto;
    position: relative;
 }
 .global-study-image .image::before {
    content: "";
    position: absolute;
    width: 270px;
    height: 345px;
    left: -99px;
    top: -116px;
    background-image: url('./circles-group.png');
    background-position: 20px 100px;
    background-repeat: no-repeat;
}
.image .custom-img{
  width: 250px;
  height: 250px;
  border-radius: 10px;
  transform: rotate(75deg);
  overflow: hidden;
  box-shadow: 24px 28px 12px #aba9a9;
}
.global-study-image .image .custom-img {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    transform: rotate(75deg);
    overflow: hidden;
    box-shadow: 0px 0px 12px #aba9a9;
}

.image .custom-img img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform: skew(75deg, -75deg);
  object-fit: contain;
  object-position: center 75px
}
.global-study-image .image .custom-img img {
    width: 367px;
    height: 367px;
    -webkit-transform: rotate(-75deg);
    transform: rotate(-75deg);
    object-fit: cover;
    max-width: 367px;
    object-position: center;
    margin-top: -25px;
    margin-left: -35px;
}

.image .red {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #EB585E;
    position: absolute;
    bottom: -35px;
    left: -35px;
    box-shadow: -11px 12px 12px #aba9a9;
}
.image .blue {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #2E81F7;
    position: absolute;
    bottom: -55px;
    right: 165px;
    box-shadow: -11px 12px 12px #aba9a9;
}
.global-study-image .image .green {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #0CBB80;
    position: absolute;
    bottom: -30px;
    right: 200px;
    box-shadow: -11px 12px 12px #aba9a9;
    z-index: 1;
}

.global-study-image .image .blue {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #2E81F7;
    position: absolute;
    top: -35px;
    z-index: 1;
    right: 35px;
}
.image .orange {
    width: 270px;
    height: 70px;
    border-radius: 10px;
    background-color: #FFAE3B;
    position: absolute;
    bottom: 10px;
    right: -72px;
    box-shadow: -11px 12px 12px #aba9a9;
}
.custom-checkbox{
    margin-left: 0 !important;
    color: #3895FF !important;
}
.custom-checkbox .MuiCheckbox-colorPrimary.Mui-checked {
    color: #3895FF !important;
}
.reg-form{
    /* max-height: calc(100vh - 140px);
    overflow-y: auto; */
    padding-bottom: 30px;
}
.branch-name{
    font-size: 18px;
    font-weight: bold;
}
.login-right-heading {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 32px;
    text-align: center;
    color: #454545;
    font-weight: bold;
}
.login-sub-heading {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 16px;
    color: #454545;
    text-align: center;
}
button{cursor: pointer; font-family: "Lato" !important;}
.header-left a{
    cursor: pointer;
}
.contact-heading{
    color: #454545;
    font-size: 24px;
}
.dialog-heading{
    color: #454545;
    font-weight: bold;
    margin-top: 15px;
    display: block;
    font-size: 18px;
}
.dialog-body{
    margin-top: 15px;
    display: block;
    font-size: 18px;
}
.dialog-footer{
    text-align: center;
    padding-bottom: 25px !important;
    justify-content: center !important;
}
.btn-cancel {
    background-color: #3895FF !important;
}
.btn-cancel:hover {
    background-color: #2070ca !important;
}
.btn-logout {
    background-color: #EB585E !important;
}
.btn-logout:hover {
    background-color: #d32f34 !important;
}
button a {
    color: #fff !important;
    text-decoration: none !important;
}
.my-courses-btns{
    width: 200px;
    margin: 0 0 15px auto;
}
.my-courses-btns button{
    width: 100% !important;
    padding: 9px 25px !important;
    margin-top: 15px !important;
    color: #fff !important;
}
.sub-heading{
    color: #454545;
    margin-bottom: 5px;
}
.syllabus ol{
    padding-left: 15px;
    margin-top: 0;
}
.syllabus ol li{
    line-height: 24px;
}
.btn-test {
    color: #0DBD7F !important;
    font-weight: 600 !important;
    width: 100% !important;
    padding: 5px 40px !important;
    font-size: 14px !important;
}
.btn-test.MuiButton-contained.Mui-disabled {
    color: #0DBD7F !important;
}
.questions {
    list-style: none;
    margin: 0 -10px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.questions li{
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.questions li button{
    border-radius: 12px;
    margin: 0 auto 15px;
    width: 45px;
    height: 45px;
    max-width: 50px;
    background-color: #3895FF;
    color: #fff;
    font-size: 18px;
    min-width: auto !important;
    font-family: "Lato";
}
.questions li button:hover{
    background-color: #3895FF;
    opacity: 1;
}
.answered-un{
    list-style: none;
    margin: 0;
    padding: 10px 0 35px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    color: #454545;
}
.answered-un li {
    color: #454545;
    display: flex;
    align-items: center;
}
.answered-un li svg{
    font-size: 18px;
    color: #8b8b8b;
    margin-right: 5px;
}
.answers-widget {
    display: flex;
    flex-direction: column;
    padding: 0 0 10px;
    width: 100%;
    color: #454545;
}
.answers-widget p{
    display: block;
    padding: 0px;
    margin: 0;
    font-weight: 600;
}
.test-series-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0;
}
.btn-previous{
    color: #454545 !important;
    margin-right: 15px !important;
}
.btn-danger {
    background-color: #EB585E !important;
    padding: 5px 25px !important;
    text-transform: none !important;
}
.ques-widget{
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    box-shadow: 0px 0px 6px #cacaca;
}
.ques-widget-bottom{
    text-align: center;
}
.question-box{
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px;
}
.question-box-right{
    border-radius: 10px;
}
.ques-left{
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    padding: 30px;
    font-family: "Lato";

}
.skip {
    text-align: right; 
    color: #EB585E;
    text-decoration: underline;
    padding: 5px 0 15px;
    font-style: italic;
    font-size: 14px;
}
.skip a{
    cursor: pointer;
}
.ques{
    /* padding: 0 15px; */
    margin-bottom: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}
.ques p{
    font-weight: bold;
    color: #454545;
    font-size: 18px;
}
.ques-option {
    padding: 0 15px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    font-family: "Lato";
}
.ques-option span{
    font-family: "Lato" !important;
}
.btn-end,
.test-series-btns button {
    padding: 9px 40px !important;
}
input[readonly],
input:read-only {
    background-color: #ccc;
}
.test-series-heading{
    font-size: 21px;
    color: #454545;
    margin: 0;
}
.test-time{
    margin: 0;
    color: #454545;
}
/* input:disabled,
input[type="date"]::before {
	color: #757575;
	content: "Date of Birth";
    position: absolute;
    background-color: #fff;
    width: 150px;
}
input[type="date"]:focus::before {
	content: "" !important;
} */
.form-select:first-child{
	color: #757575;  
}
.question-bank-box{
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px;
    padding: 30px;
}
.question-bank-box ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.MuiRadio-colorPrimary.Mui-checked {
    color: #3794FE !important;
}
.MuiRadio-colorPrimary.Mui-checked + .MuiFormControlLabel-label {
    color: #3794FE !important;
}
.MuiSvgIcon-root {
    width: 0.8em !important;
    height: 0.8em !important;
}
.MuiSvgIcon-root.PrivateRadioButtonIcon-layer-12 {
    transform: scale(.8) !important;
}
.question-bank-widget{
    padding-bottom: 30px;
    border-bottom: 2px dashed #7B9CC0;
}
.question-bank-footer{
    display: flex;
    justify-content: space-between;
}
.question-bank-footer button{
    padding: 9px 40px !important;
}
.question-bank-block{
    margin-bottom: 15px;
}
.question-bank-box li.question-bank-block:last-child .question-bank-widget{
    border-bottom: none;
}
.list-desc p {
    margin: 0;
}
.my-course{
    display: flex;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px;
    margin-bottom: 40px;
    flex-wrap: wrap;
}
.my-course-left{
    /* height: 100%; */
    background-color: rgb(12, 187, 128);
    border-radius: 8px;
    padding: 30px;
    flex: 0 0 22%;
    box-shadow: 16px 15px 15px #d3d3d3;
}
.my-course-left img{
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.my-course-time{
    margin-top: 20px;
}
.my-course-time-m{
    color: #fff;
    text-align: center;
    font-size: 16px;
}
.my-course-time-m h3{
    margin-top: 5px;
    margin-bottom: 15px;
}
.my-course-right{
    padding: 35px 45px;
    flex: 1;
}
.course-time {
    font-size: 22px;
    padding: 0 2px 20px;
}
.p{
    font-size: 16px !important;
    line-height: 24px;
}
.pay-info{
    text-align: right;
}
.location-list {
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;
}
.location-info{
    margin-bottom: 15px !important;
}
.btn-gohome{
    padding: 10px 20px !important;
    border: 1px solid #3895FF !important;
    background-color: #3895FF !important;
    border-radius: 50px;
}
.btn-gohome:hover{
    border: 1px solid #1f78dd !important;
    background-color: #1f78dd !important;
}
.contact-cards{
    max-width: 1000px;
    margin: 0 auto !important;
}
.contact-card-info{
    font-size: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    transform: rotate(5deg);
}
.contact-card-info p {
    margin: 10px 0;
    letter-spacing: 3px;
    font-weight: 500;
}
.contact-card-info p:first-child {
    font-size: 24px;
}
.contact-card-info.toll-free p:nth-child(2) {
    font-size: 24px;
}
.contact-card-info.email {
    transform: rotate(-5deg);
    box-shadow: none;
}
.contact-card-info.email p {
    letter-spacing: 0px;
    margin: 3px 5px;
}
.address-in,
.contact-num{
    letter-spacing: 2px;
    margin-bottom: 5px;
    font-family: "Lato";
    font-size: 18px;
    font-weight: bold;
}
.add-details {
    margin: 20px 0px;
}
.address-info{
    color: #454545;
    font-size: 18px;
}
.registration .form-select,
.registration .form-control{
    border: 1px solid #efefef;
}
.btn-change{
    margin: 0 auto !important;
}
.studym-info{
    padding:  15px 10px;
}
.studym-info-left p{
    margin: 5px 0;
    font-weight: bold;
}
.study-image{
    border-radius:5px;
}
.study-image img{
    border-radius:5px;
}
.demo-video{
    width: 100%;
}
.online-course {
    list-style: none;
    margin: 0;
    padding: 0;
}
.online-course li{
    margin-bottom: 40px;
}
.online-course-info{
    display: flex;
    box-shadow: 0px 0px 6px #cacaca;
    padding: 30px;
    border-radius: 15px;
    text-decoration: none;
    color: #454545;
    align-items: center;
    flex-wrap: wrap;
}
.online-course-image {
    width: 200px;
    height: 200px;
    display: flex;
    margin-right: 30px;
}
.online-course-image img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}
.online-course-type{
    color: #454545;
    margin-top: 0;
}
.num-gap{
    padding-bottom: 7px;
}
.syllabus-p p {
    margin-top: 0;
    margin-bottom: 7px;
}
input[type="date"]::before {
	color: #999999;
	content: attr(placeholder);
}
input[type="date"] {
	color: #ffffff;
}
input[type="date"]:focus,
input[type="date"]:valid {
	color: #666666;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
	content: "" !important;
}
.demo-slider-widget .slick-slide {
    text-align: center;
}
.my-courses{
    margin: 0;
    padding: 0;
    list-style: none;
}
.ellipsis-3{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.course-desc{
    text-align: justify;
    color: #454545;
}
input[type="date"]::before {
	color: #75758b;
	content: attr(placeholder);
}
input[type="date"] {
	color: #ffffff;
}
input[type="date"]:focus,
input[type="date"]:valid {
	color: #666666;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
	content: "" !important;
}
.popper-menu{
    position: absolute !important;
}
/* .section-1{
    max-width: 900px;
    margin: 0 auto;
} */
.gallery-widget ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.gallery-widget ul li{
    flex: 0 0 25%;
    height: 275px;
}
.gallery-widget ul li .gallery-image-info{
    margin: 10px;
    text-align: center;
    height: 100%;
    cursor: pointer;
}
.gallery-widget ul li .gallery-image-info img{
    width: 100%;
    object-fit: contain;
    height: 100%;
    border-radius: 15px;
}
.gallery-widget ul li:nth-of-type(2n+2) .gallery-image-info img{
    width: 85%;
}
.model-gallery{
    list-style: none;
    margin: 0;
    padding: 0;
}
.selected-image{
    max-height: calc(100vh - 100px);
}
.selected-image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: calc(100vh - 200px);
}
.gallery-slide{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.gallery-slide:focus-visible{
    outline: none;
}
.gallery-slide svg{
    color: #fff;
    font-size: 50px;
    cursor: pointer;
}
.closeGallery{
    position: fixed;
    top: 10px;
    right: 10px;
}
.closeGallery svg{
    font-size: 50px;
    color: #fff;
}
.closeGallery svg:hover{
    font-size: 50px;
    color: #f00;
}
#simple-menu,
#simple-menu >div[aria-hidden="true"] {
    top: 55px !important;
}
#simple-menu .MuiMenu-paper {
    top: 0px !important;
}
.about-decsription p{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}
.read-more{
    font-size: 16px !important;
    font-family: "Lato";
    color: rgb(7, 151, 187);
    margin-bottom: 20px;
    display: block;
}
.read-more2{
    font-size: 16px !important;
    font-family: "Lato";
    color: rgb(7, 151, 187);
    text-decoration: none;
}
.btn-no-cursor{
    cursor: default !important;
}
.about-widget{
    background-image: url('./background.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}
.about-sec1{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}
.about-sec1-left,.about-sec1-right {
    padding: 20px;
}
.about-sec1-left {
    flex: 0 0 60%;
}
.about-sec1-right {
    flex: 0 0 40%;
    margin-top: 20px;
    padding-left: 60px;
}
.about-sec1-left p{
    line-height: 36px;
    color: #454545;
    font-weight: 600;
    text-align: justify;
    margin-top: 0;
}
.about-images{
    margin: 50px 0 0;
}
.about-images ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.about-images ul li{
    padding: 0;
    margin: 0;
    flex: 0 0 33.33%;
    position: relative;
}
.about-images ul li .about-img-info{
    margin: 20px;
}
.about-images ul li .about-img-info h3{
    color: #454545;
    text-align: center;
    text-transform: uppercase;
    padding: 0 60px;
    line-height: 26px;
}
.about-images ul li img{
    min-height: 300px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 12px #cacaca;
    object-fit: cover;
}
.about-images ul li .number{
    font-size: 105px;
    font-weight: bold;
    position: absolute;
    top: -34px;
    right: 3px;
    font-family: "Lato";
}
.digital-library{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.digital-library li{
    flex: 0 0 33.33%;
}
.digital-library-info{
    margin: 20px;
    box-shadow: 0px 0px 6px #cacaca;
    border-radius: 10px;
}
.digital-library-image{
    display: block;
}
.digital-library-image img{
    width: 100%;
    border-radius: 10px 10px 0 0;
    height: 275px;
    object-fit: cover;
}
.digital-library-content{
    display: flex;
    /* align-items: center; */
    padding: 0 15px 15px;
    justify-content: space-between;
    flex-direction: column;
}
.digital-library-content h3{
    margin-bottom: 0;
}

/* home slider */
.home-slide-image  {
    width: 80%;
    margin: 0 auto;
    padding: 50px 50px;
 }
.home-slide-image .image  {
    width: 100%;
    margin: 0 auto;
    position: relative;
 }
 .home-slide-image .image::before {
    content: "";
    position: absolute;
    width: 270px;
    height: 345px;
    left: -99px;
    top: -116px;
    background-image: url('./circles-group.png');
    background-position: 20px 100px;
    background-repeat: no-repeat;
}
.home-slide-image .image::before {
    display:none;
}
.home-slide-image .image .custom-img {
    height: 100%;
    border-radius: 20px;
    transform: rotate(-7deg);
    width: 100%;
    box-shadow: -12px 12px 27px #767676;
}
.home-slide-image .image .custom-img img {
    width: 100%;
    height: 100%;
    transform: rotate(0deg);
    object-position: center;
}
.home-slide-image .image .green {
    width: 200px;
    height: 60px;
    border-radius: 10px;
    background-color: #0CBB80;
    position: absolute;
    bottom: inherit;
    right: inherit;
    box-shadow: -11px 12px 12px #aba9a9;
    z-index: 0;
    left: -90px;
    top: 110px;
    transform: rotate(-10deg);
}
.home-slide-image .image .blue {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #2E81F7;
    position: absolute;
    bottom: -6px;
    z-index: 1;
    left: -7px;
    top: inherit;
}
.home-slide-image .image .orange {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #FFAE3B;
    position: absolute;
    bottom: inherit;
    right: -17px;
    box-shadow: -11px 12px 12px #3e3e3e96;
    top: -19px;
    z-index: 1;
}
/* home slider end*/
#zmmtg-root {
	display: none;
}
.no-underline{
    text-decoration: none;
    display: block;
}
.no-underline.disabled{
    cursor: no-drop;
    opacity: 0.7;
}
.pay-info{
    display: flex;
    align-items: center;
}
.pay-info button{
    margin-left: 5px;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #3895ff !important;
}
.paid-on{
    display: block;
    font-weight:900;
    color: #9b9b9b;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}
.text-center {
    text-align: center;
}
.dialog-header{
    font-size: 20px;
    font-family: "Lato";
    font-weight: bold;
    margin: 15px 0;
    display: block;
}
.no-batches{
    display: block;
}
.already-paid{
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}
.already-paid span{
    color: #3f51b5;
    cursor: pointer;
}
::-webkit-input-placeholder ,
:-ms-input-placeholder,
::placeholder,
input::placeholder,
.form-control::placeholder,
.form-select,
select {
    font-size: 13px;
    color: gray;
}
.subjects-topics{
    display: flex;
    grid-template-columns: auto auto;
    column-gap: 25px;
    align-items: flex-start;
    flex-wrap: wrap;
}
.subjects-topics .square-course {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-flex;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    width: 33.33%;
}
.subjects-topics .square-course li{
    margin: 0;
    cursor: pointer;
}
.subjects-topics .square-course li:nth-child(5){
    grid-column: 1;
    grid-column-end: 3;
}
.subjects-topics .square-course li:nth-child(8){
    grid-column: 1;
    grid-column-end: 3;
}
.subjects-topics .square-course li .square-content{
    border-radius: 7px;
    min-height: 150px;
}
.subjects-topics .square-course li.active .square-content{
    background-color: #3895FF;
    color: #fff;
}
.topic-section{
    flex: 1;
}
.topic-section .square-content{
    border-radius: 7px;
    padding: 0;
    display: block;
}
.topic-section__header{
    border-bottom: 1px solid #ccc;
    padding: 0px 0 0 70px;
    min-height: 100px;
    display: flex;
    align-items: center;
}
.topic-section__footer{
    border-top: 1px solid #ccc;
    padding: 10px 25px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.topic-section__footer p{
    color: #858585;
    font-style: italic;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Lato';
    padding-right: 15px;
}
.topic-section__footer button{
    flex: none;
}
.topics{
    padding: 20px 20px 20px 25px;
}
.btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}
.topic .Mui-checked .MuiIconButton-label .MuiSvgIcon-root {
    color: #3895FF;
}
.topic .Mui-checked + .MuiFormControlLabel-label {
    color: #3895FF;
}
.topic .MuiFormControlLabel-label {
    font-weight: bold;
}
.notifications-count{
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
}
.notifications-count .MuiSvgIcon-root{
    font-size: 32px;
}
.notifications-count .notifications-num{
    position: absolute;
    top: 0px;
    right: 5px;
    background-color: #f00;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 5px;
}
.header-right{
    display: flex;
    align-items: center;
}
.answer-box{
    border-radius: 15px;
    padding: 5px 20px;
    font-weight: 600;
}
.ques {
    color: #3895FF;
    color: #fff;
    border-radius: 4px;
    font-weight: 900;
    font-size: 16px;
}
.unselect {
    opacity: 0.5
}
.answers-group{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    width: 100%;
}
.ques-option .MuiFormControlLabel-root {
    width: 100%;
}
.score {
    font-size: 52px;
    font-weight: 600;
}
.dailog-box{
    text-align: center;
    min-width: 380px;
}
.MuiButton-contained.Mui-disabled {
    color: #fff !important;
    opacity: 0.5;
}
.image-success {
    padding: 10px;
    position: relative;
}
.image-success img{
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
}
.imageInfo{
    position: absolute;
    bottom: 10px;
    left: 20px;
    width: calc(100% - 41px);
    color: #fff;
    font-size: 18px;
}
.classesP{
    margin: 5px 0;
}
.success-story-list{
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
}
.success-story-list li .image-success .reponsive{
    box-shadow: 0px 0px 6px #cacaca;
    height: 285px;
}
.success-story-list li:nth-child(6n+1){
    width: 65%;
}
.success-story-list li:nth-child(6n+2){
    width: 35%;
}
.success-story-list li:nth-child(6n+3){
    width: 35%;
}
.success-story-list li:nth-child(6n+4){
    width: 65%;
}
.success-story-list li:nth-child(6n+5){
    width: 50%;
}
.success-story-list li:nth-child(6n+6){
    width: 50%;
}
.success-story-modal iframe{
    border: none;
    border-radius: 10px;
}
.scorecard-right button {
    margin-left: 10px;
}
.question-options {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-columns: auto auto;
}
.question-options label{
    flex: 0 0 50%;
    padding: 10px;
    border: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    border-radius: 4px;
}
iframe .player{
    height: 100% !important;
}
.notificcations-left {
    display: flex;
    padding: 25px 0 25px 25px;
    cursor: pointer;
}
.notificcations-right {
    display: flex;
    padding: 25px 25px 25px 0;
}
.notifications-list ul li {
    display: grid;
    grid-template-columns: auto auto;
    padding: 0;
}
.form-div{
    padding: 20px;
}
.backTohome {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
.backTologin {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
}
.backTohome a{
    display: flex;
    align-items: center;
    font-style: italic;
    color: #292C95;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}
.backTohome a span{
    text-decoration: underline;
}
.already-account {
    text-align: center;
}
.login-here {
    border: none;
    cursor: pointer;
    padding: 12px 25px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    font-weight: 500;
    border-radius: 20px;
    color: #3895FF !important;
    background-color: #E3EDF8 !important;
}
/* contact us styles start */

.numbers {
    position: relative;
    padding: 0 10px;
    font-size: 36px;
    font-weight: bold;
    display: inline-block;
}
.numbers::after {
    content: "";
    height: 22px;
    width: 19px;
    border-radius: 20px;
    background: #d0e6ff;
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 15px;
    z-index: -1;
}
.numbers2 {
    position: relative;
    padding: 0 10px;
    font-size: 36px;
    display: inline-block;
    font-weight: bold;
}
.numbers2::after {
    content: "no-close-quote";
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background: #d0e6ff;
    display: inline-block;
    position: absolute;
    left: -2px;
    top: -6px;
    z-index: -1;
}
.counters {
    position: relative;
    display: inline-block;
    padding-right: 50px;
    cursor: pointer;
    color: #3895ff;
    font-weight: bold;
}
.counters::before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 54px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #0CBB80;
    display: inline-block;
}
.location {
    display: inline-block;
    padding-right: 50px;
    cursor: pointer;
    font-weight: bold;
    color: #454545;
}
.locations {
    margin: 10px;
    display: flex;
    justify-content: "";
    font-size: 36px;
    font-weight: bold;
}
.block {
    margin: 20px;
}
.cardBack {
    background-image: url('./circles_img.png');
}
.callCover {
    padding-top: 20px;
    padding-left: 20px;
    height: 250px;
}
.emailCover {
    padding-top: 20px;
    padding-left: 20px;
    height: 250px;
}
.tollFreeCover {
    padding-top: 20px;
    padding-left: 20px;
    height: 250px;
}
.call {
    background-color: #3895ff;
    color: white;
    text-align: center;
    height:100%;
    width: 90%;
    transform: rotate(-5deg);
    padding: 30px 10px;
    border-radius: 8px;
    position: relative;
    box-shadow: 2px 3px 4px 4px #ccc;
} 
.email {
    background-color: #0CBB80;
    color: white;
    text-align: center;
    height: 100%;
    width: 90%;
    transform: rotate(6deg);
    padding: 30px 10px;
    border-radius: 8px;
    position: relative;
    word-wrap: break-word;
    box-shadow: 2px 3px 4px 4px #ccc;

}
.tollFree {
    background-color: orange;
    color: white;
    text-align: center;
    height: 100%;
    width: 90%;
    transform: rotate(-5deg);
    padding: 30px 10px;
    border-radius: 8px;
    position: relative;
    box-shadow: 2px 3px 4px 4px #ccc;
}
.tollFreeImage {
    position: absolute;
    top: -2px;
    right: 3px;
    width: 20px;
}
.tollFreeImageBack {
    position: absolute;
    top: -19px;
    right: -11px;
    width: 51px;
}
.emailImg {
    position: absolute;
    top: 88%;
    left: 90%;
    width: 20px;
}
.emailImgBack {
    position: absolute;
    width: 55px;
    top: 79%;
    left: 83%;
}
.phoneImgBack {
    position: absolute;
    top: -14px;
    width: 51px;
    left: -7px;
}
.phoneImg {
    position: absolute;
    top: 0px;
    left: 8px;
    width: 20px;
}
/* contact us styles end */
.bouquet{
    padding-right: 5px;
    width: 25px;
}
/* .folder-widget{
    display: flex;
    flex-wrap: wrap;
} */
.folder{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
    cursor: pointer;
}
.breadcrumb-data{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 10px 0 !important;
    
}
.breadcrumb-data li{
    flex: 0 0 auto !important;
    padding: 10px 0 10px 0px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
}
.folders{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.divider{
    padding: 0 10px;
}
.question-image{
    height: 200px;
    margin-right: 10px;
}
.answer-image{
    height: 150px;
    margin-right: 10px;
}
.question-video,.answer-video {
    background-color: #000;
    border: 0px;
}
.vp-center {
    justify-content: flex-start !important;
}
.option-label {
    display: flex;
    cursor: pointer;
    padding-bottom: 5px;
}
.field-row {
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    column-gap: 10px;
}
.btn-mobile-profile .MuiSvgIcon-root{
    color: #FFFFFF !important;
}
.user_name {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: auto;
    color: #999;
    cursor: default;
    background-color: transparent;
}
.user_name:hover {
    background-color: transparent;
}
.notifications-count-mobile {
    margin-right: 5px;
    padding: 0px 10px 0px 5px;
}
.notifications-count-mobile .MuiSvgIcon-root {
    color: #fff;
}
.notifications-count-mobile .notifications-num {
    top: -10px;
}
.headding-mb{
    margin-bottom: 90px;
}
.testimonials-slider {
    margin-bottom: 90px;
}
.testimonials-slider .global-study-image {
    padding: 50px 0 50px;
    text-align: center;
}
.register-left {
    flex: 1;
}
.live-btn {
    background-color: #0CBB80 !important;
    color: #fff !important;
}
.btn-login-mobile {
    padding: 5px 15px !important;
}
.rectangle-box {
    width: 200px;
    height: 85px;
    border-radius: 5px;
    background-color: #0CBB80;
    position: absolute;
    bottom: inherit;
    right: inherit;
    box-shadow: -11px 12px 12px #A9AEB4;
    z-index: -1;
    transform: rotate(-20deg);
}
.rectangle-box.green {
    background-color: #0CBB80;
    top: 40px;
    left: 20px;
}
.rectangle-box.red {
    background-color: #EB585D;
    bottom: 35px;
    right: 27px;
}
/* Media Queries */
@media(max-width: 1024px){
    .headding-mb{
        margin-bottom: 20px;
    }
    .header-left img{
        width: 175px;
    }
    .header {
        padding: 10px 15px;
    }
    .header-middle{
        position: absolute;
        top: 55px;
        left: 0;
        width: 100%;
        background-color: #fff;
        padding: 10px;
        transition: all 0.3s;
    }
    .header-middle .menu-widget{
        flex-direction: column;
    }
    .main-content{
        padding: 0px 15px;
    }
    .main-content .top-gap{
        padding-top: 55px !important;
    }
    .main-content.main-content-no-pdding{
        padding-top: 0px;
    }
    .top-section{
        padding: 0;
        flex-direction: column;
    }
    .top-section-left,
    .top-section-right{
        flex: 0 0 100%;
    }
    .top-section-left h1{
       font-size: 21px;
    }
    .top-section-left button {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .upcoming-batches {
        display: flex;
        grid-template-columns: none;
        grid-gap: 0;
        gap: 0;
        margin-top: 30px;
        flex-direction: column;
    }
    .courses-list {
        grid-template-columns: auto auto;
        gap: 50px 25px;
        justify-content: center;
    }    
    .courses-list li{
        flex-basis: calc(50% - 34px);
    }
    .new-courses .slick-next {
        right: 0;
        z-index: 1;
    }   
    .new-courses .slick-prev {
        left: 0;
        z-index: 1;
    }
    .batch-info {
        margin-bottom: 20px;
    }
    .batch-info h2 {
        color: #454545;
        font-size: 21px;
    }
    .batch-info-image {
        float: left;
        margin-right: 20px;
    } 
    .batch-info img {
        width: 150px;
        height: 150px;
    }
    .why-nursing-experts {
        padding-top: 0px;
    }
    .section-heading p {
        padding: 0px 40px 0px;
    }
    .why-nursing-experts ul li {
        padding-right: 50px;
    }
    .success-story {
        flex-direction: column;
    }
    .section-heading h3 {
        margin-top: 20px;
    }
    .our-testimonials {
        flex-direction: column;
    }
    .register-info h3 {
        margin: 0;
        font-size: 18px;
    }
    .register-info p {
        margin-top: 5px;
        font-size: 14px;
    }
    .register-left{
        width: 60%;
    }
    .btn-register {
        font-size: 12px !important;
    }
    .footer-widget footer {
        flex-direction: column;
        align-items: flex-start;
    }
    .footer-widget {
        padding: 50px 0 0;
    }
    .footer-left-ul {
        margin-top: 55px;
    }
    .footer-left-ul p {
        width: 100%;
    }
    .footer-left-ul a {
        font-size: 16px;
    }
    footer .footer-left, footer .footer-middle {
        margin-top: 30px;
    }
    .footer-middle{
        display: flex;
        width: 100%;
        margin-bottom: 30px;
    }
    .footer-block{
        flex: 0 0 33.33%;
    }
    .profile-view {
        flex-direction: column;
    }
    .profile-details {
        padding-left: 0px;
        padding-top: 20px;
    }
    .classroom-courses,
    .vacancies ul{
        justify-content: center;
    }
    .scorecard-list ul {
        margin: 0px;
    }
    .scorecard-list ul li {
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
    }
    .scorecard-info h3 {
        margin: 10px 0 0;
        font-size: 18px;
    }
    .course-type {
        font-size: 16px;
    }
    .scorecard-list .date {
        font-size: 14px;
    }
    .question-papers li {
        flex: 0 0 50%;
    }
    .fgtPswrdImg img {
        position: absolute;
        width: 350px;
        top: 435px;
        left: 25px;
    }
    .fgtPswrdOTPImg img {
        position: absolute;
        width: 350px;
        top: 445px;
        left: 25px;
    }
    .newPswrdImg img {
        position: absolute;
        width: 350px;
        top: 420px;
        left: 5px;
    }
    .setPswrdImg img {
        position: absolute;
        width: 350px;
        top: 435px;
        left: 25px;
    }
}
@media(max-width: 767px){
    header {
        background-color: #3695FF;
    }
    .btn-toggle svg{
        color: #fff;
    }
    .courses-list {
        grid-template-columns: auto;
        gap: 50px 15px;
    }    
    .courses-list li{
        flex-basis: 100%;
    }
    .main-content .register {
        padding: 10px 0 !important;
    }
    .question_papers,
    .courses {
        background-image: none
    }
    .new-courses {
        min-height: 250px;
    }
    .our-testimonials-left .name {
        font-size: 16px;
        padding-bottom: 10px;
    }
    .section-heading p {
        padding: 0px 0px 0px;
        text-align: left;
    }
    .our-testimonials-left {
        padding-right: 0px;
    }
    .register img {
        display: none;
    }
    .register .register-info {
        padding: 10px;
        position: relative;
        flex-direction: column;
        background-color: #3995fe;
        border-radius: 7px;
    }
    .register-left {
        width: 100%;
    }
    .register-right {
        width: 100%;
        text-align: center;
    }
    .batch-info-image {
        float: none;
        margin-right: 0px;
    }
    .batch-info {
        margin-bottom: 40px;
    }
    .batch-info h2 {
        font-size: 18px;
        margin-bottom: 0;
    }
    .batch-info p {
        margin: 0;
        line-height: 21px;
        font-size: 14px;
    }
    .batch-info-image {
        margin-top: 0px;
    }
    .section-heading h3 {
        margin: 10px 0;
    }
    .why-nursing-experts ul {
        margin: 25px 0 0px;
        justify-content: flex-start;
        flex-direction: column;
    }
    .why-nursing-experts ul li {
        padding-right: 0px;
        margin-bottom: 25px;
    }
    .success-story-left h3{
        margin-bottom: 0;
    }
    .success-story-left p {
        line-height: 24px;
        font-size: 14px;
    }
    p{
        line-height: 24px !important;
        font-size: 14px;
    }
    .footer-block{
        flex: 0 0 100%;
    }
    .footer-widget {
        padding: 20px 0 0;
    }
    .footer-middle {
       flex-direction: column;
    }
    footer .footer-right{
        width: 100%;
        padding-top: 10px;
    }
    .profile-name h1 {
        font-size: 21px;
    }
    .profile-sub {
        flex-direction: column;
    }
    .profile-image span {
        width: 100%;
        height: auto;
    }
    .profile-sub .form-group {
        margin-bottom: 15px;
    }
    .profile-details {
        flex-direction: column-reverse;
    }
    .profile-sub .form-group {
        flex: 0 0 100%;
    }
    .field-value {
        font-size: 16px;
        margin-top: 2px;
    }
    .profile-details-left {
        padding-top: 20px;
    }
    .profile-details-right {
        padding-left: 0px;
    }
    .side-by-side .form-group:last-child {
        padding-left: 0px;
    }
    .footer-left-ul {
        margin-top: 0px;
    }
    .footer-widget::before {
        background-image: none;
    }
    footer .footer-left {
        margin-top: 0px;
    }
    .classroom-courses,
    .vacancies ul{
        margin: 0;
    }
    .classroom-info,
    .vacancy-info {
        margin: 0px 0px 15px 0;
    }
    .scorecard-list .rank,
    .scorecard-list .marks,
    .scorecard-list .status {
        font-size: 16px;
        padding: 5px 0px;
    }
    .scorecard-list ul li {
        margin-bottom: 15px;
    }
    .result {
        padding-top: 5px;
    }
    .scorecard-list .date {
        padding-bottom: 5px;
    }
    .recorded-videos ul li .record-content {
        align-items: center;
    }
    .recorded-videos ul li {
        flex: 0 0 100%;
    }
    .recorded-videos ul li .record-content {
        flex-direction: column;
        margin: 0 15px 15px;
        padding: 20px;
        align-items: flex-start;
    }
    .record-info h3 {
        margin: 10px 0;
        font-size: 16px;
    }
    .record-date {
        font-size: 14px;
    }
    .record-time {
        font-size: 16px;
    }
    .login {
        flex-direction: column;
        padding: 20px;
    }
    .login-left {
        border-radius: 20px 20px 0px 0px !important;
    }
    .login-left::before {
        border-radius: 20px 20px 0px 0px !important;
    }
    .login-right {
        border-radius: 0px 0px 20px 20px !important;
    }
    .login-left h3 {
        margin-top: 35px;
        line-height: 30px;
    }
    .login-left img {
        display: none;
    }
    .login-left-mobile {
        display: block;
    }
    .login-right >div {
        padding: 0 20px;
    }
    .our-programs ul {
        flex-direction: column;
    }
    .program-content {
        margin: 5px 15px;
    }
    .program-info h3 {
        padding: 0 80px;
        font-size: 16px;
        margin-top: 0px;
    }
    .course-list {
        padding-left: 0;
    }
    .demo-slider-widget .slick-slide .demo-slider .demo-content {
        margin: 0 20px;
    }
    .address {
        margin-top: 0px;
        padding: 15px;
    }
    .address p{
        font-size: 16px;
    }
    .quote img {
        width: 15px;
    }
    .square-course {
        justify-content: space-between;
    }
    .square-course li{
        margin-right: 0px;
    }
    .square-content {
        min-width: 110px;
        min-height: 110px;
    }
    .square-content h3 {
        font-size: 16px;
    }
    .documents-required h3, .registration h3 {
        font-size: 16px;
    }
    .registration {
        margin-top: 0px;
    }
    .registration .form-group{
        margin: 0;
    }

    .address::before {
        width: 130px;
        height: 100%;
        right: -25px;
        top: -25px;
        background-size: 90px;
    }
    .global-study-image {
        padding-left: 0px;
    }
    .question-papers li {
        flex: 0 0 100%;
        padding-top: 10px;
    }
    .question-papers-name {
        font-size: 16px;
    }
    .programs-web{
        display: none !important;
    }
    .sub-menu-widget {
        display: flex;
        flex-direction: column;
    }
    .multi-preview li .upload-div{
        min-width: 90px;
        min-height: 90px;
    }
    .multi-preview img {
        width: 80px;
        height: 80px;
    }
    .pdf-file svg {
        font-size: 45px;
    }
    .upcoming-batches .batch-info:nth-child(2) .batch-info-image,
    .upcoming-batches .batch-info:nth-child(5) .batch-info-image,
    .upcoming-batches .batch-info:nth-child(8) .batch-info-image{
        order: 0;
    }
    .testimonals-list li {
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .testimonals-list li .image {
        width: 100%;
        order: -1;
        max-width: 355px;
        margin: 0 auto;
    }
    .testimonals-info {
        width: 100%;
    }
    .testimonals-list {
        padding: 25px 0 0;
    }
    .testimonals-list li:nth-of-type(even) .testimonals-info {
        padding-left: 0px;
        padding-right: 0px;
    }
    .welcome-widget {
        background-position: bottom;
        background-size: contain;
    }
    .welcome-info img {
        left: 50%;
        top: 50px;
        transform: translateX(-50%);
    }
    .image .orange {
        right: 0px;
    }
    .testimonals-info {
        padding: 50px 0 0;
    }
    .image .custom-img {
        width: 230px;
        height: 230px;
        margin-left: 50px;
    }
    .image .red {
        left: 20px;
    }
    .image .blue {
        bottom: -40px;
    }
    .reg-form {
        max-height: 100%;
    }
    .login-info {
        padding-top: 0px
    }
    .login-right-heading {
        font-size: 24px;
    }
    .cap {
        transform: translate(15px, 30px);
    }
    .about-sec1-left,
    .about-sec1-right{
        flex: 0 0 100%;
    }
    .about-images ul li {
        flex: 100%;
    }
    .about-sec1-right {
        padding-left: 20px;
        order: -1;
    }
    .digital-library li{
        flex: 0 0 100%;
    }
    .digital-library-content {
        padding: 0px 15px 10px;
        flex-direction: column;
    }
    .section-heading {
        margin-bottom: 10px;
    }
    .digital-library-info {
        margin: 10px 0px;
    }
    .success-story-list li,
    .success-story-list li:nth-child(6n+1),
    .success-story-list li:nth-child(6n+2),
    .success-story-list li:nth-child(6n+3),
    .success-story-list li:nth-child(6n+4),
    .success-story-list li:nth-child(6n+5),
    .success-story-list li:nth-child(6n+6) {
        width: 100% !important;
    }
    .success-story-list li .image-success .reponsive {
        height: 200px;
    }
    .header{
        background-image: none;
    }
    .demo-slider-widget .slick-slide .demo-slider .demo-content iframe{
        width: 100% !important;
        height: 100% !important;
    }
    .notifications-list ul li {
        grid-template-columns: auto;
    }
    .scorecard-img svg {
        font-size: 55px;
    }
    .emailCover {
        padding-top: 30px;
    }
    .tollFreeCover {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .my-course {
        margin-bottom: 20px;
    }
    .my-course-left{
        flex: 1;
    }
    .course-btns {
        justify-content: center !important;
    }
    .classroom-courses li {
        margin: 10px 0;
    }
    .online-course li {
        margin-bottom: 20px;
    }
    .online-course-info-right {
        margin-top: 10px;
    }
    .online-course-type, .online-course-desc {
        margin: 0;
    }
    .global-study-image .image {
        width: 100%;
        margin: 0;
    }
    .global-study-image .image .custom-img {
        width: 100%;
        height: auto;
        transform: rotate(0deg);
        margin: 0;
        box-shadow: none;
    }
    .global-study-image .image .custom-img img{
        transform: rotate(0deg);
        margin: 0;
        max-width: 100%;
        height: auto;
    }
    .global-study-image .image::before{
        display: none;
    }
    .subjects-topics .square-course {
        margin-bottom: 20px;
        width: 100%;
    }
    .header-left a {
        display: flex;
    }
    .btn-no-mobile {display: none;}
    .our-testimonials-right {
        margin: 50px 0;
    }
    .testimonials-slider .our-testimonials-right {
        margin: 0;
    }
    .testimonials-slider .global-study-image {
        padding: 30px 0 50px 0px;
    }
    .testimonials-slider {
        margin-bottom: 45px;
    }
    .batch-info img {
        width: 100%;
        height: auto;
    }
    .home-slide-image {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .home-slide-image .image .blue {
        width: 20px;
        height: 20px;
        left: 0;
    }
    .home-slide-image .image .orange {
        width: 30px;
        height: 30px;
        right: 0;
    }
    .home-slide-image .image .green {
        display: none;
    }
    .home-slide-image .image .custom-img {
        height: auto;
        border-radius: 5px;
        transform: rotate(0deg);
        margin: 0;
        box-shadow: none;
    }
    .home-slider {
        margin-bottom: 20px;
    }
    .courses {
        margin-top: 30px;
    }
    .header-left img {
        width: 130px;
    }
    .rectangle-box {
        width: 100px;
        height: 40px;
    }
    .rectangle-box.green {
        background-color: #0CBB80;
        top: 5px;
        left: 0px;
    }
    .rectangle-box.red {
        background-color: #EB585D;
        bottom: 5px;
        right: 0px;
    }
    .success-story-right img {
        width: 100%;
        height: auto;
    }
    .success-story-right {
        padding: 10px;
    }
}